import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const MenuDrawer = ({ setIsOpen, isOpen }) => {
  const pageName = [
    { name: "Support" },
    { name: "About" },
    { name: "Privacy Policy" },
    { name: "Terms of Service" },
  ];

  const handleLogout = () => {
    window.location.reload();
    localStorage.clear();
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-20 bg-gray-500 bg-opacity-75 ">
          <div className="fixed inset-0 flex items-center justify-end ">
            <div className="relative w-screen max-w-sm bg-white shadow-xl h-full">
              <div className="flex justify-between items-center p-4">
                <p className="text-2xl font-semibold text-gray-900">Menu</p>
                <button
                  className="hover:bg-gray-100 p-1 rounded-full text-2xl"
                  onClick={() => setIsOpen(false)}
                >
                  <Icon icon="ion:close-outline" />
                </button>
              </div>
              <div className="py-6 px-6 flex flex-col text-gray-900 text-lg">
                {localStorage.getItem("token") && (
                  <Link
                    to="/profile"
                    onClick={() => setIsOpen(false)}
                    className="pb-4"
                  >
                    Profile
                  </Link>
                )}
                {localStorage.getItem("token") && (
                  <Link
                    to="/profile"
                    onClick={() => setIsOpen(false)}
                    className="pb-4"
                  >
                    Setting
                  </Link>
                )}
                {pageName.map((data, index) => (
                  <Link key={index} to="#" className="pb-4">
                    {data.name}
                  </Link>
                ))}
                <hr />
                {localStorage.getItem("token") && (
                  <button onClick={handleLogout} className="pt-4 text-left">
                    Log out
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MenuDrawer;
