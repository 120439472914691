import React, { useEffect } from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PlaceAdd from "./Pages/PlaceAdd";
import Flight from "./Pages/Flight";
import Car from "./Pages/Car";
import Activity from "./Pages/Activity";
import PlanAddress from "./Pages/PlanAddress";
import Signup from "./Pages/Signup";
import Login from "./Pages/Login";
import ResetPassword from "./Pages/ResetPassword";
import ForgotPassword from "./Pages/ForgotPassword";
import Profile from "./Pages/Profile";
import Plans from "./Pages/Plans";
import PlaceList from "./Pages/PlaceList";

import Header from "./Component/Header";
import { PrivateRoute } from "./Component/PrivateRoute";
import PlaceEdit from "./Component/PlaceEdit";
import { useRootContext } from "./context";

import Shareauthorization from "./Component/Shareauthorization";

import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import "./Assets/css/style.css";

const withPrivateRoute = (WrappedComponent) => {
  return (props) => (
    <PrivateRoute>
      <WrappedComponent {...props} />
    </PrivateRoute>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: withPrivateRoute(Plans)(),
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "signup",
        element: <Signup />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "Shareauthorization",
        element: <Shareauthorization />,
      },
      {
        path: "plans/:plansId",
        element: withPrivateRoute(PlaceList)(),
      },
      {
        path: "places/:id",
        element: withPrivateRoute(PlanAddress)(),
      },
      {
        path: "placeAdd",
        element: withPrivateRoute(PlaceAdd)(),
      },
      {
        path: "PlaceEdit/:id",
        element: withPrivateRoute(PlaceEdit)(),
      },
      {
        path: "profile",
        element: withPrivateRoute(Profile)(),
      },
      {
        path: "flight",
        element: withPrivateRoute(Flight)(),
      },
      {
        path: "car",
        element: withPrivateRoute(Car)(),
      },
      {
        path: "activity",
        element: withPrivateRoute(Activity)(),
      },
      {
        path: "planAddress",
        element: withPrivateRoute(PlanAddress)(),
      },
      {
        path: "sharePlan/:sharetoken",
        element: <PlaceList />,
      },
    ],
  },
]);

function App() {
  const { setToken } = useRootContext();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) setToken(token);
  }, [setToken]);

  return <RouterProvider router={router} />;
}

function Layout() {
  // let getKey = React.useCallback((location, matches) => {
  //   let match = matches.find((m) => m.handle?.scrollMode);

  //   if (match?.handle?.scrollMode === "pathname") {
  //     return location.pathname;
  //   }

  //   return location.key;
  // }, []);

  return (
    <>
      <ToastContainer autoClose={2000} position="top-right" hideProgressBar />
      <Header />
      <Outlet />
      {/* <ScrollRestoration getKey={getKey} /> */}
    </>
  );
}

export default App;
