import React, { useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { SpinnerLoading } from "../Component/SpinnerLoading";
import axiosClient from "../Component/axiosClient";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is a required field"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is a required field"),
});

const Signup = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const signup = async (values) => {
    try {
      setLoading(true);
      await axiosClient.post(`/api/user/signup`, JSON.stringify(values));
      navigate("/login");
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="loadingSpinner">
          <SpinnerLoading isLoading={loading} />
        </div>
      )}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-8">
        <div className="mx-auto sm:w-full sm:max-w-sm bg-gray-100 p-6 rounded-md">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
            <h2 className="text-center text-2xl font-semi leading-9 tracking-tight text-gray-900">
              Sign up for Away Planner
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto w-full sm:max-w-sm">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={DisplayingErrorMessagesSchema}
              onSubmit={(values) => {
                signup(values);
              }}
            >
              {({ errors, touched, handleSubmit }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  className="space-y-6"
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-1.5">
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-primary-500 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-8"
                      />
                      {touched.email && errors.email && (
                        <div className="text-red-900">{errors.email}</div>
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Password
                      </label>
                    </div>
                    <div className="mt-1.5 relative">
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-primary-500 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-8"
                      />
                      {touched.password && errors.password && (
                        <div className="text-red-900">{errors.password}</div>
                      )}
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex ml-auto mr-auto justify-center rounded-md bg-primary-700 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Create an account
                    </button>
                  </div>
                </form>
              )}
            </Formik>
            <p className="text-center tracking-tight text-gray-900 mt-5">
              By clicking "Create account", you agree to the&nbsp;&nbsp;
              <span className="underline">Away</span>
              <br />
              <span className="underline">TOS</span>&nbsp; and&nbsp;
              <Link to="" className="underline cursor-pointer">
                Privacy Policy.
              </Link>
            </p>

            <p className="mt-6 text-center text-md">
              <Link
                to="/login"
                className="font-semibold leading-6 text-primary-700 hover:text-primary-600"
              >
                Login to your account
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
