import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import MenuDrawer from "./MenuDrawer";
import logo from "../Assets/image/Logo.svg";
import { useRootContext } from "../context/index";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { token, setIsNewPlan, noPlans } = useRootContext();
  const navigate = useNavigate();

  const handleallplan = () => {
    navigate("/");
  };
  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex items-center justify-between p-4 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1 ">
          <Link to="/" className="-m-1.5 p-1.5">
            <img className="h-8 w-auto" src={logo} alt="" />
          </Link>
        </div>

        <div className="flex items-center ">
          {!token ? (
            <>
              <NavLink
                to="/login"
                className="smallMobile:text-sm text-[10px] font-semibold leading-6 text-gray-900 hover:bg-gray-100 smallMobile:p-2 p-1 rounded-md"
              >
                Log in
              </NavLink>
              <NavLink
                to="/signup"
                className="smallMobile:text-sm text-[10px] rounded-md bg-primary-700 font-semibold  text-white smallMobile:p-2 p-1  ml-2 hover:bg-primary-600"
              >
                Get started
              </NavLink>
            </>
          ) : (
            <>
              <div className="flex items-center">
                <button
                  onClick={handleallplan}
                  type="button"
                  className="flex items-center smallMobile:text-sm text-[10px] rounded-md font-semibold text-blue-700  bg-white  smallMobile:p-2 p-1  border-2 border-blue-700  "
                >
                  <Icon
                    icon="gravity-ui:list-ul"
                    style={{
                      marginRight: "5px",
                    }}
                  />
                  See all plans
                </button>
                {!noPlans && (
                  <button
                    onClick={() => {
                      setIsNewPlan(true);
                      navigate("/PlaceAdd");
                    }}
                    className="smallMobile:text-sm text-[10px] rounded-md font-semibold text-blue-700 border-2 border-blue-700 smallMobile:p-2 p-1 ml-2"
                  >
                    + New plan
                  </button>
                )}
              </div>
            </>
          )}

          <button
            className="hover:bg-gray-100 smallMobile:p-2 p-1 rounded-full text-2xl"
            onClick={() => setIsOpen(true)}
          >
            <Icon icon="lucide:menu" />
          </button>
        </div>
      </nav>
      {isOpen && <MenuDrawer isOpen={isOpen} setIsOpen={setIsOpen} />}
    </header>
  );
};

export default Header;
