import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import BottomFabBtn from "../Component/BottomFabBtn";
import { useParams } from "react-router-dom";
import { SpinnerLoading } from "../Component/SpinnerLoading.js";
import axiosClient from "../Component/axiosClient.js";
import AppleMapComponent from "../Component/placeDetails/AppleMapComponent.js";
import PlaceComments from "../Component/placeDetails/placeDetails.js";
import moment from "moment";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const PlanAddress = () => {
  const [data, setData] = useState({});
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const item = state?.item;
  const shareLink = state?.id;

  useEffect(() => {
    if (item) {
      setData(item);
    }
  }, [item]);

  const markerLocation = useMemo(() => {
    if (item?.latitude && item?.longitude) {
      return {
        latitude: Number(item.latitude),
        longitude: Number(item.longitude),
      };
    }
    return null;
  }, [item]);

  const date = data?.date;

  const formateDate = moment(date?.split("T")[0]).format("dddd, MMMM D, YYYY");
  const formateTime = item?.time;
  const plansId = data?.plansId;

  const handleBackPage = () => {
    navigate(`/plans/${data?.plansId}`);
    // window.history.back();
  };

  const handleEditPage = (id) => {
    navigate(`/placeEdit/${id}`, {
      state: {
        plansId,
        date: data?.date,
        time: data?.time,
        address: data?.address,
        city: state?.item?.city,
        id: state?.item?.id,
      },
    });
  };

  const handleDeletePlan = async (id) => {
    try {
      setLoading(true);
      await axiosClient.delete(`/api/plans/place/${plansId}/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setLoading(false);
      handleBackPage();
    } catch (error) {
      setLoading(false);
      navigate("/");
      console.log(error);
    }
  };

  const EditMenu = () => {
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 border-0 bg-white px-3 py-2 text-sm font-lg text-gray-900  hover:bg-gray-50">
            <Icon icon="tabler:dots-vertical" style={{ color: "black" }} />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="#"
                    onClick={() => handleDeletePlan(data.placeId)}
                    className={classNames(
                      active ? "bg-gray-100 text-red-700" : "text-red-700",
                      "block px-4 py-2 text-md"
                    )}
                  >
                    Delete Plan
                  </Link>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const resultArray = data?.address?.split(", ");
  const area = resultArray?.[0];

  return (
    <>
      {loading && (
        <div className="loadingSpinner">
          <SpinnerLoading isLoading={loading} />
        </div>
      )}
      <div className="desktop:w-1/2 laptop:w-1/2 tablet:w-8/12 mobile:w-full mx-auto md:px-0 smallMobile:px-5 px-3">
        <div className="plan">
          <div className="flex items-center justify-between py-3 gap-5">
            <button
              className="font-sans mobile:font-normal smallMobile:font-normal text-gray-900 capitalize flex items-center"
              onClick={() => handleBackPage()}
            >
              <Icon
                icon="prime:chevron-left"
                sx={{ fontSize: "20px" }}
                width={"1.5rem"}
                height={"1.5rem"}
              />
              Back
            </button>
            <div className="flex items-center">
              <div className="flex items-center">
                <button
                  onClick={() => handleEditPage(id)}
                  className="font-sans font-normal  text-gray-900 capitalize"
                >
                  Edit
                </button>
              </div>
              <EditMenu />
            </div>
          </div>
        </div>
        <div className="mobile:px-0 px-3">
          <div className="mt-6">
            <Icon
              icon="heroicons:building-office"
              style={{
                fontSize: "44px",
                backgroundColor: "#84E1BC",
                padding: "2px 8px",
                color: "rgba(1, 71, 55, 1)",
                borderRadius: "40px",
              }}
            />
            <p className="flex items-center mobile:text-lg text-md	font-semibold	mt-4">
              {data[0]?.name || area}
              <br />
              {formateTime}, {formateDate}
            </p>
            <p className="flex items-center mobile:text-xl text-lg	font-normal	mt-4 	">
              {data?.address}
            </p>
            <br />
          </div>
          <div className="mt-5 px-5">
            {markerLocation && data.address && (
              <AppleMapComponent
                markerLocation={markerLocation}
                title={data.address}
              />
            )}
          </div>
        </div>

        {plansId && (
          <PlaceComments
            shareLink={shareLink}
            placeId={id}
            plansId={plansId}
            item={item}
          />
        )}
      </div>
      {/* <BottomFabBtn plansId={data?.plansId} /> */}
    </>
  );
};

export default PlanAddress;
