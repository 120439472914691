import { Field, Formik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// import { toast } from "react-toastify";
import * as Yup from "yup";
import { SpinnerLoading } from "../Component/SpinnerLoading";
import axiosClient from "../Component/axiosClient";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is a required field"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);

  // const param = useParams();
  const handleResetPassword = async (pass) => {
    try {
      setLoading(true);
      const param1 = queryParams.get("token");
      await axiosClient.post(`/api/user/reset-password?token=${param1}`, pass);
      // toast.success("password updated!");
      navigate("/login");
    } catch (error) {
      // toast.error(error.message);
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {loading && (
        <div className="loadingSpinner">
          <SpinnerLoading isLoading={loading} />
        </div>
      )}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-8">
        <div className="mx-auto w-96 sm:w-full sm:max-w-lg bg-gray-100 p-6 rounded-md">
          <div className="lg:mx-auto sm:w-full sm:max-w-lg ">
            <h2 className="text-center text-2xl font-semi leading-9 tracking-tight text-gray-900">
              Away Planner - Reset Password
            </h2>
          </div>

          <div className="mt-6 lg:mx-auto sm:w-full sm:max-w-lg">
            <Formik
              initialValues={{
                password: "",
              }}
              validationSchema={DisplayingErrorMessagesSchema}
              onSubmit={(values) => {
                handleResetPassword(values);
              }}
            >
              {({ errors, touched, handleSubmit }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  className="space-y-6"
                >
                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="password"
                        className="block text-center text-sm font-medium leading-6 text-gray-900"
                      >
                        Password
                      </label>
                    </div>
                    <div className="mt-1.5 relative">
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-primary-500 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-8"
                      />

                      {touched.password && errors.password && (
                        <div className="text-red-900">{errors.password}</div>
                      )}
                    </div>
                  </div>
                  {/* <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-center text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                  </div>
                  <div className="mt-1.5 relative">
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-primary-500 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-8"
                    />

                    {touched.password && errors.password && (
                      <div className="text-red-900">{errors.password}</div>
                    )}
                  </div>
                </div> */}
                  <div>
                    <button
                      type="submit"
                      className="flex ml-auto mr-auto justify-center rounded-md bg-primary-700 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Reset Password
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
