/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { throttle } from "throttle-debounce";

import BottomFabBtn from "../Component/BottomFabBtn";
import { SpinnerLoading } from "../Component/SpinnerLoading";
import axiosClient from "../Component/axiosClient";
import SharePlanList from "../Component/SharePlanList";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

function sortByTime(arr) {
  return arr.sort((a, b) => {
    const timeA = convertTo24Hour(a.time);
    const timeB = convertTo24Hour(b.time);
    return timeA.localeCompare(timeB);
  });
}

function convertTo24Hour(time) {
  const [rawTime, period] = time.split(" ");
  let [hours, minutes] = rawTime.split(":");
  hours = parseInt(hours);

  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes}`;
}

const PlaceList = () => {
  const [sharePopUpOpen, setSharePopUpOpen] = useState(false);
  const [editName, setEditName] = useState(false);
  const navigate = useNavigate();
  const { sharetoken, plansId } = useParams();
  const [userData, setUserData] = useState(null);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [sortdata, setSortData] = useState({});
  const token = localStorage.getItem("token");
  const { state } = useLocation();

  let decodedToken;
  let shareId;
  let planId;
  let userId;
  try {
    decodedToken = jwtDecode(sharetoken);
  } catch (error) {}
  if (decodedToken) {
    ({ planId, userId, shareId } = decodedToken);
  }
  const planListId = plansId ? plansId : planId;
  const sharedId = shareId ? shareId : state?.item?.shareId;

  useEffect(() => {
    const handleScroll = throttle(500, () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
    });
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchUserData();
    fetchAllPlan();
  }, [sharedId]);

  const scrollToPervious = () => {
    const scrollPosition = Number.parseInt(
      sessionStorage.getItem("scrollPosition"),
      10
    );

    if (scrollPosition) {
      setTimeout(() => {
        window.scrollTo({
          // behavior: "smooth",
          top: scrollPosition,
        });
      }, 2);
    }
  };

  const handleSharedPlanLink = async () => {
    if (!sharedId || !planListId || !userData || !userData.id) {
      console.error("handleSharedPlanLink: Missing required parameters", {
        sharedId,
        planListId,
        userData,
      });
      return;
    }

    try {
      const response = await axiosClient.post(
        `/api/shareplanslist/shareplanslist`,
        {
          shareId: sharedId,
          planId: planListId,
          userId: userData?.id,
        },
        {
          headers: { Authorization: token },
        }
      );

      await response.data;
      setLoading(false);
    } catch (error) {
      console.error("handleSharedPlanLink: Error", error);
      navigate("/login");
    }
  };

  const fetchUserData = async () => {
    try {
      const res = await axiosClient.get(`/api/user/getUser`, {
        headers: { Authorization: `${token}` },
      });

      if (res.status === 200) {
        setUserData(res.data);
      }
      setLoading(false);
    } catch (error) {
      navigate("/login");
      console.log(error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate(`/Shareauthorization`);
    } else {
      if (sharedId && userData) {
        if (userData.id === userId) {
          navigate(`/Shareauthorization`);
        } else {
          handleSharedPlanLink();
        }
      }
    }
  }, [userData]);

  const deleteSharedPlan = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");

      await axiosClient.delete(`/api/shareplanslist/deleteShareablePlan`, {
        headers: {
          Authorization: token,
        },
        data: {
          shareId: sharedId,
          userId: userData?.id,
        },
      });

      navigate("/");
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchAllPlan = async () => {
    try {
      if (!token) {
        navigate(`/Shareauthorization`);
      } else {
        setLoading(true);
        const res = await axiosClient.get(`/api/plans/${planListId}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setTitle(res.data?.name);
        const sortedArray = res.data?.places;
        if (sortedArray) {
          Object.keys(sortedArray).map((key) => {
            sortedArray[key] = sortByTime(sortedArray[key]);
          });
          setSortData(sortedArray);
        }
        setLoading(false);
        scrollToPervious();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response.status === 400) {
        return;
      }
      // toast.error("please login again");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setEditName(false);
      editPlans(planListId);
    }
  };

  const deletePlans = async (planListId) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      await axiosClient.delete(`/api/plans/${planListId}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      navigate("/");
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        return navigate("/");
      }
      setLoading(false);
      // toast.error("please login again");
    }
  };

  const editPlans = async (planListId) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      await axiosClient.put(
        `/api/plans/${planListId}`,
        {
          name: title,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      fetchAllPlan();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      // toast.error("please login again");
    }
  };

  const handleBackPage = () => {
    setSortData("");
    navigate(`/`);
    // window.history.back();
  };

  const handleNewMap = (item) => {
    navigate(`/places/${item.placeId}`, { state: { item } });
  };

  const handleDeleteplan = async (sharedId, planListId) => {
    if (sharedId) {
      deleteSharedPlan(sharedId);
    } else {
      deletePlans(planListId);
    }
  };

  const dataEntery = Object.entries(sortdata);

  return (
    <>
      {loading ? (
        <div className="loadingSpinner">
          <SpinnerLoading isLoading={loading} />
        </div>
      ) : (
        <div className="desktop:w-1/2 laptop:w-1/2 tablet:w-8/12 mobile:w-full mx-auto md:px-0 smallMobile:px-5 px-3">
          {!shareId && (
            <div className="plan">
              <div className="flex items-center justify-between py-3 gap-5 ">
                <button
                  className="font-sans mobile:font-normal smallMobile:font-normal text-gray-900 capitalize flex items-center"
                  onClick={() => handleBackPage()}
                >
                  <Icon
                    icon="prime:chevron-left"
                    sx={{ fontSize: "20px" }}
                    width={"1.5rem"}
                    height={"1.5rem"}
                  />
                  Back
                </button>
                {!editName ? (
                  <p
                    className=" font-normal mr-3 text-black"
                    style={{ fontWeight: "500" }}
                  >
                    {title}
                  </p>
                ) : (
                  <input
                    type="text"
                    className="w-full px-3 py-2 border rounded-lg border-none focus:outline-none focus:border-blue-500"
                    placeholder="Enter text"
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                )}
                <div className="flex items-center">
                  <button
                    className="font-sans smallMobile:font-normal  font-normal text-gray-900 capitalize"
                    onClick={() => setSharePopUpOpen(!sharePopUpOpen)}
                  >
                    Share
                  </button>

                  {state?.isShared !== true && (
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex justify-center gap-x-1.5 border-0 bg-white pl-3 py-2 text-sm font-lg text-gray-900 hover:bg-gray-50">
                          <Icon
                            icon="tabler:dots-vertical"
                            style={{ color: "black" }}
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <span
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                  onClick={() => {
                                    setEditName(!editName);
                                  }}
                                >
                                  Edit Name
                                </span>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <span
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-red-700"
                                      : "text-red-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                  onClick={() =>
                                    handleDeleteplan(sharedId, planListId)
                                  }
                                >
                                  Delete
                                </span>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="h-full w-full relative">
            <div className="line-box border-l-2 w-full absolute h-[calc(100%-18px)]" />

            {dataEntery.length > 0 ? (
              dataEntery.map(([key, value], index) => {
                return (
                  <div className="h-full w-full relative" key={index}>
                    {value?.map((item, cityIndex) => {
                      const lastparentCity =
                        sortdata[Object.keys(sortdata)[index - 1]]?.at(-1)
                          ?.city === item?.city;
                      const innerlastCity =
                        value[cityIndex - 1]?.city === item?.city;
                      const isSameCity =
                        cityIndex === 0 ? lastparentCity : innerlastCity;

                      const resultArray = item?.address?.split(", ");
                      const area = resultArray?.[0] || "";

                      return (
                        <div className="mt-[1rem]" key={index + cityIndex}>
                          <div className="text-gray-600 font-sans text-md">
                            {!isSameCity && (
                              <>
                                {index !== 0 && (
                                  <hr
                                    className="ms-5 mb-5"
                                    style={{
                                      border: "none",
                                      height: "2px",
                                      color: "#e2e8f0",
                                      backgroundColor: "#e2e8f0",
                                    }}
                                  />
                                )}
                                <div className="flex items-center gap-4">
                                  <span className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-400 text-slate-500 ring-2 ring-white">
                                    <Icon
                                      icon={"heroicons:building-office"}
                                      className="m-[0.37rem] p-0"
                                      width="3rem"
                                      height="2.5rem"
                                      style={{ color: "#000" }}
                                    />
                                  </span>
                                  <div className="flex flex-col flex-1 gap-1">
                                    <p className="text-gray-500 font-bold text-base">
                                      {item?.city}
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                            {cityIndex === 0 && (
                              <p className="text-gray-500 font-medium pl-[48.5px] my-5">
                                <span className="inline-block relative right-[30.5px] text-gray-500">
                                  •
                                </span>
                                {moment(key).format("dddd, MMMM D, YYYY")}
                              </p>
                            )}

                            <div
                              className="flex cursor-pointer mt-5 items-center"
                              onClick={() => {
                                item.plansId = planListId;
                                handleNewMap(item);
                              }}
                            >
                              <div className="flex items-center gap-4">
                                <span className="flex items-center justify-center w-10 h-10 rounded-full bg-green-300 text-slate-700 ring-2 ring-white">
                                  <Icon
                                    icon={"heroicons:building-office"}
                                    style={{
                                      fontSize: "20px",
                                      color: "bg-green-300",
                                    }}
                                  />
                                </span>
                                <div className="flex flex-col flex-1 gap-0 py-4">
                                  <h4 className="text-base font-medium text-black">
                                    {item?.time}• {area}
                                  </h4>
                                  <p className="text-sm text-gray-500">
                                    {item?.address}
                                  </p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <button className="flex items-center">
                                  <Icon
                                    icon="uiw:right"
                                    style={{ fontSize: "20px" }}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            ) : (
              <div className="text-center mt-5">NO PLACES</div>
            )}
          </div>
        </div>
      )}

      {!sharedId && <BottomFabBtn plansId={planListId} />}

      {sharePopUpOpen && (
        <SharePlanList
          sharePopUpOpen={sharePopUpOpen}
          setSharePopUpOpen={setSharePopUpOpen}
          onClose={() => setSharePopUpOpen(false)}
          planListId={planListId}
          permissionLevel={"view"}
          userId={userData.id}
        />
      )}
    </>
  );
};
export default PlaceList;
