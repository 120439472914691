import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import BottomFabBtn from "../Component/BottomFabBtn";
import { SpinnerLoading } from "../Component/SpinnerLoading";
import { useNavigate } from "react-router-dom";
import { useRootContext } from "../context";
import axiosClient from "../Component/axiosClient";

const Plans = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setNoPlans } = useRootContext();
  const navigate = useNavigate();

  const fetchAllPlans = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const res = await axiosClient.get(`/api/plans/allPlans`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setData(res.data);
      setNoPlans(false);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 404) {
        navigate("/");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlanClick = (item) => {
    sessionStorage.setItem("scrollPosition", 0);
    navigate(`/plans/${item.id}`);
  };

  return (
    <>
      {loading && (
        <div className="loadingSpinner">
          <SpinnerLoading isLoading={loading} />
        </div>
      )}
      {data && data.personalPlans?.length > 0 ? (
        <div className="desktop:w-1/2 laptop:w-1/2 tablet:w-8/12 mobile:w-full mx-auto">
          <div className="border-gray-200 border-b-2 pb-5 px-4">
            <h1 className="text-[24px] font-light">Your plans</h1>
          </div>
          <ul className="divide-y px-4 divide-gray-100">
            {data?.personalPlans?.map((item, i) => (
              <li
                key={i}
                className="flex items-center justify-between gap-x-6 py-5 cursor-pointer"
                onClick={() => handlePlanClick(item)}
              >
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <div className="flex items-center justify-start mb-3 w-full">
                      <p className="text-base pr-2 font-medium leading-6 text-gray-900">
                        {item?.name}
                      </p>
                      <div className="flex justify-end items-start">
                        {item.isShared ? (
                          <span
                            style={{
                              border: "1px solid rgb(29 78 216)",
                              padding: "2px 3px",
                              borderRadius: "8px",
                              color: "rgb(29 78 216)",
                              fontSize: "10px",
                            }}
                          >
                            Shared
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <p className="mt-1 truncate text-sm font-normal leading-5 text-gray-500">
                      {item.dateInterval
                        ? item.dateInterval
                        : `September 18 – 20, 2023`}
                    </p>
                  </div>
                </div>
                <button>
                  <Icon
                    className="text-gray-500 text-4xl"
                    icon="ri:arrow-right-s-line"
                  />
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="desktop:w-5/12 laptop:w-5/12 tablet:w-8/12 mobile:w-full mx-auto mt-4 rounded-lg text-center">
          <div className="text-gray-500 text-2xl">
            Press + to start planning
          </div>
        </div>
      )}
      <br></br>
      <br></br>
      {data && data.sharedPlans?.length > 0 ? (
        <div className="desktop:w-1/2 laptop:w-1/2 tablet:w-8/12 mobile:w-full mx-auto">
          <div className="border-gray-200 border-b-2 pb-1 px-4">
            <h1 className="text-[24px] font-light">Share plans</h1>
          </div>

          <ul className="divide-y px-4 divide-gray-100">
            {data.sharedPlans?.map((item, i) => (
              <li
                key={i}
                className="flex items-center justify-between gap-x-6 py-5 cursor-pointer"
                onClick={() => {
                  navigate(`/plans/${item.id}`, {
                    state: { ...item, isShared: true },
                  });
                }}
              >
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="text-base font-medium leading-6 text-gray-900">
                      {item?.name}
                    </p>
                    <p className="mt-1 truncate text-sm font-normal leading-5 text-gray-500">
                      {item?.dateInterval}
                    </p>
                  </div>
                </div>
                <button>
                  <Icon
                    className="text-gray-500 text-4xl"
                    icon="ri:arrow-right-s-line"
                  />
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <></>
      )}
      <BottomFabBtn />
    </>
  );
};
export default Plans;
