import React from "react";
import { useNavigate } from "react-router-dom";
import question from "../Assets/image/26da36fa5b621bd90b0a7ec755833ea9.png";

function Shareauthorization() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="flex justify-center items-center h-screen text-center ">
      <div className="flex flex-col lg:flex-row items-center w-full h-5/6">
        <div className=" lg:mt-0">
          <img src={question} alt="Error" className="h-96" />
        </div>
        {/* Left Side - Text */}
        <div className="flex flex-col items-center mt-6">
          <h1 className="text-5xl font-bold mb-4">Something's Wrong here...</h1>
          <p className="text-2xl text-center w-3/5 mb-6">
            You are not logged in, to view the plan please login.
          </p>
          <button
            onClick={handleClick}
            className="text-sm rounded-md font-semibold leading-6 text-blue-700 border-2 border-blue-700 px-2 py-1 mx-3"
          >
            Go to login
          </button>
        </div>

        {/* Right Side - Image */}
      </div>
    </div>
  );
}

export default Shareauthorization;
