import { createContext, useContext, useState } from "react";

export const RootContext = createContext(null);

export const useRootContext = () => {
  const root = useContext(RootContext);
  return root;
};

export const ContextProvider = (props) => {
  const [items, setItems] = useState([]);
  const [editPlan, setEditPlan] = useState([]);
  const [token, setToken] = useState(null);
  const [isNewPlan, setIsNewPlan] = useState(false);
  const [noPlans, setNoPlans] = useState(false);
  const [clickedButton, setClickedButton] = useState();
  const [data, setData] = useState([]);

  return (
    <RootContext.Provider
      value={{
        items,
        setItems,
        token,
        setToken,
        editPlan,
        setEditPlan,
        isNewPlan,
        setIsNewPlan,
        noPlans,
        setNoPlans,
        setClickedButton,
        clickedButton,
        setData,
        data,
      }}
    >
      {props.children}
    </RootContext.Provider>
  );
};
