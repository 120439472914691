import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useRootContext } from "../context";

const BottomFabBtn = ({ plansId }) => {
  const [speedDialer, setSeepDialer] = useState(false);
  const { setIsNewPlan } = useRootContext();

  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    /* .blur-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(8px);
    -webkit-filter: blur(8px);
  } */

    if (speedDialer) {
      document.body.classList.add("blur-background");
    } else {
      document.body.classList.remove("blur-background");
    }
  }, [speedDialer]);

  const handleNewPlace = () => {
    setIsNewPlan(location.pathname.startsWith("/plans") ? false : true);
    navigate("/PlaceAdd", {
      state: {
        plansId,
      },
    });
  };

  const handleCarPlace = () => {
    navigate("/car");
  };

  const handleFlightPlace = () => {
    navigate("/flight");
  };

  const handleActivityPlace = () => {
    navigate("/activity");
  };

  return (
    <>
      <div
        data-dial-init="true"
        className="fixed mobile:bottom-6 mobile:right-10 bottom-6 right-6 group z-10"
      >
        <div
          id="speed-dial-menu-text-outside-button-square"
          className={`flex flex-col items-center bg-white ${
            speedDialer ? "" : "hidden"
          }  mb-4 space-y-2`}
        >
          <button
            onClick={handleNewPlace}
            type="button"
            className="relative w-14 h-14 flex items-center justify-center text-gray-500 bg-[#84E1BC] rounded-md border border-gray-200 hover:text-gray-900 shadow-sm"
          >
            <Icon
              icon="heroicons:building-office"
              className="text-3xl m-2 text-[#014737]"
            />
            <span className="absolute block mb-px text-md font-medium -translate-y-1/2 -left-20 top-1/2 bg-white py-5 pl-4 pr-6">
              Place
            </span>
          </button>

          <button
            type="button"
            className="relative w-14 h-14 flex items-center justify-center text-gray-500 bg-[#d8b4fe] rounded-md border border-gray-200 hover:text-gray-900 shadow-sm"
            onClick={handleFlightPlace}
          >
            <Icon
              icon="ph:airplane"
              className="text-3xl m-2 text-[#581c87] font-bold"
            />
            <span className="absolute block mb-px text-md font-medium -translate-y-1/2 -left-20 top-1/2 bg-white  py-5 pl-4 pr-5">
              Flight
            </span>
          </button>
          <button
            type="button"
            className="relative w-14 h-14 flex items-center justify-center text-gray-500 bg-[#7EDCE2] rounded-md border border-gray-200 hover:text-gray-900 shadow-sm"
            onClick={handleCarPlace}
          >
            <Icon
              icon="bi:car-front-fill"
              className="text-2xl m-3 text-[#014451]"
            />
            <span className="absolute block mb-px text-md font-medium -translate-y-1/2 -left-20 top-1/2 bg-white py-5 pl-4 pr-9">
              Car
            </span>
          </button>
          <button
            type="button"
            className="relative w-14 h-14 flex items-center justify-center text-gray-500 bg-[#F8B4D9] rounded-md border border-gray-200  hover:text-gray-900 shadow-sm"
            onClick={handleActivityPlace}
          >
            <Icon
              icon="heroicons:sparkles-solid"
              className=" text-2xl m-3 text-[#751A3D] "
            />

            <span className="absolute block mb-px text-md font-medium -translate-y-1/2 -left-20 top-1/2 bg-white  py-5 pl-4 pr-1">
              Activity
            </span>
          </button>
        </div>
        <button
          type="button"
          data-dial-toggle="speed-dial-menu-text-outside-button-square"
          aria-controls="speed-dial-menu-text-outside-button-square"
          className={`flex items-center justify-center text-white bg-blue-700 rounded-full w-14 h-14 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800 ${
            speedDialer ? "group-hover:rotate-90" : ""
          }`}
          // onMouseEnter={() => setSeepDialer(!speedDialer)}
          // onMouseLeave={() => setSeepDialer(!speedDialer)}
          onClick={() => setSeepDialer(!speedDialer)}
        >
          {speedDialer ? (
            <Icon
              icon="ic:round-close"
              fontSize={24}
              style={{ padding: "0px" }}
            />
          ) : (
            <Icon
              icon="octicon:plus-16"
              fontSize={25}
              style={{ padding: "0px" }}
            />
          )}
          <span className="sr-only">Open actions menu</span>
        </button>
      </div>
    </>
  );
};

export default BottomFabBtn;
