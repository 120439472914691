import React, { Fragment, useEffect, useRef, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { Icon } from "@iconify/react";
import axiosClient from "./axiosClient";
import { useNavigate } from "react-router-dom";

function SharePlanList({
  planListId,
  permissionLevel,
  sharePopUpOpen,
  setSharePopUpOpen,
  onClose,
  userId,
}) {
  const cancelButtonRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [shareLink, setShareLink] = useState(null);
  const [userData, setUserData] = useState(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleClosePopup = () => {
    onClose();
    setTimeout(() => {
      setSharePopUpOpen(false);
    }, 500);
  };

  // const link = `https://localhost:3000/${planListId}`;
  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(shareLink)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch(() => {
        const textarea = document.createElement("textarea");
        textarea.value = shareLink;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
      });
  };

  const fetchShareLink = async () => {
    try {
      if (!token) {
        window.location.href = "/login";
        return null;
      }

      const response = await axiosClient.post(
        `/api/shareplanslist?planId=${planListId}`,
        {
          userId,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      return response.data.shareLink;
    } catch (error) {
      return null;
    }
  };
  useEffect(() => {
    if (planListId) {
      fetchShareLink().then((data) => {
        if (data) {
          setShareLink(data);
        }
      });
    }
  }, [planListId]);

  return (
    <div>
      <Transition.Root show={sharePopUpOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={handleClosePopup}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl w-96 transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white pl-8 pb-6 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="flex items-center justify-end pr-4">
                        <button
                          onClick={handleClosePopup}
                          className="text-gray-400"
                        >
                          <Icon icon="iconamoon:close-fill" fontSize={28} />
                        </button>
                      </div>
                      <div className="text-left sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-semibold leading-6 text-black-900 pr-8"
                        >
                          Share Plan
                        </Dialog.Title>
                        <div className="mt-2">
                          <p
                            onClick={() => {
                              handleCopyLink();
                            }}
                            className="text-sm text-gray-500 break-words pr-5"
                          >
                            {shareLink}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-8 pb-8 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      onClick={() => {
                        handleCopyLink();
                      }}
                      className="inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto"
                    >
                      {copied ? "Copied!" : "Copy Link"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default SharePlanList;
