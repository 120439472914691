import axios from "axios";

const isLocalhost = () => {
  const { hostname } = window.location;
  return hostname === "localhost" || hostname === "127.0.0.1";
};

const axiosClient = axios.create({
  baseURL: isLocalhost()
    ? process.env.REACT_APP_BASE_API_URL
    : process.env.REACT_APP_LIVE_API_URL,
  // baseURL: process.env.REACT_APP_LIVE_API_URL,
  headers: {
    "content-Type": "application/json",
  },
});

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      try {
        // toast.error("Please login again");
        localStorage.removeItem("token");
      } catch (error) {
        console.log(error, "app.js axios");
      }

      setTimeout(() => {
        window.location.href = "/";
      }, 600);
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosClient;
