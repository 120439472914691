import { useEffect, useState } from "react";
import axiosClient from "../axiosClient";
import { useNavigate } from "react-router-dom";
import { SpinnerLoading } from "../SpinnerLoading";
import { useRootContext } from "../../context";

const PlaceComments = ({ shareLink, placeId, plansId, item }) => {
  const [comment, setComment] = useState("");
  const { data, setData } = useRootContext();
  const [comments, setComments] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const fetchUserData = async () => {
    try {
      const res = await axiosClient.get(`/api/user/getUser`, {
        headers: { Authorization: `${token}` },
      });
      setUserData(res.data);
      setLoading(false);
    } catch (error) {
      navigate("/login");
      console.log(error);
    }
  };
  const fetchAllPlan = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        navigate(`/Shareauthorization`);
      } else {
        const res = await axiosClient.get(`/api/plans/${plansId}`, {
          headers: {
            Authorization: `${token}`,
          },
        });

        setData(res.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        setLoading(false);
        return;
      }
      setLoading(false);
      // toast.error("please login again");
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchAllPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansId]);
  const calculateTimeDifference = (commitDate) => {
    const now = new Date();
    const commitTime = new Date(commitDate);
    const diffInSeconds = Math.floor((now - commitTime) / 1000);

    const units = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (let unit of units) {
      const elapsed = Math.floor(diffInSeconds / unit.seconds);
      if (elapsed > 0) {
        return `${elapsed} ${unit.name}${elapsed > 1 ? "s" : ""} ago`;
      }
    }
    return "just now";
  };

  const handlePost = async () => {
    if (!comment.trim()) setComment("");
    setLoading(true);

    try {
      const payload = {
        comment: comment.trim(),
        shareId: shareLink,
        placeId: placeId,
        userId: userData?.id,
        userName: userData?.email,
      };

      const response = await axiosClient.post(
        "/api/plans/placecomment",
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.message) {
        setResponseMessage("Comment posted successfully.");
        setComments([...comments, payload])
        setComment("");
        await handleCommentedData(placeId);
        setLoading(false);
        fetchAllPlan();
      } else {
        setResponseMessage(response.data.error || "Failed to post comment.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error committing data", error);
      setResponseMessage("Failed to commit changes.");
    }
  };

  const handleCommentedData = async (placeId) => {
    try {
      setLoading(true);
      const res = await axiosClient.get(`/api/plans/commit/${placeId}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setComments(res.data); // Correctly set comments
    } catch (error) {
      console.error("Error fetching comments", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleCommentedData(placeId);
  }, [placeId]);

  return (
    <>
      {loading && (
        <div className="loadingSpinner">
          <SpinnerLoading isLoading={loading} />
        </div>
      )}
      <div className="pt-7 rounded-lg p-4 max-w-full mx-auto">
        <textarea
          className="w-full h-20 p-3 border-none outline-none resize-none text-base rounded-lg focus:ring-2 focus:ring-indigo-500"
          value={comment}
          onChange={handleChange}
          placeholder="Add your comment..."
        ></textarea>

        <div className="flex justify-between items-center mt-2">
          <div className="flex space-x-3">
            <button className="text-xl text-gray-500 hover:text-gray-700">
              😊 {/* Replace with actual icon */}
            </button>
          </div>

          <button
            className={`bg-indigo-600 text-white text-sm py-2 px-5 rounded-lg ${!comment.trim() ? "opacity-50 cursor-not-allowed" : ""
              }`}
            onClick={handlePost}
            disabled={!comment.trim()}
          >
            Post
          </button>
        </div>
        <div className="mt-6">
          {comments.length > 0 ? (
            <div className="max-h-60 overflow-y-auto">
              {comments.slice(0, 5).map((commentItem, index) => (
                <div key={index} className="bg-gray-100 p-3 rounded-lg mb-2">
                  <p className="flex items-center">
                    <span className="text-sm font-semibold">
                      {commentItem.userName || "Anonymous"}
                    </span>
                    <span className="ml-2 text-gray-400 hover:text-black text-sm">
                      {calculateTimeDifference(commentItem.createdAt)}
                    </span>
                  </p>
                  <p className="text-md">{commentItem.comment}</p>
                </div>
              ))}
            </div>
          ) : (
            <p>No comments yet. Be the first to comment!</p>
          )}
        </div>
      </div>
    </>
  );
};

export default PlaceComments;
