import BottomFabBtn from "../Component/BottomFabBtn";

const Activity = () => {
  return (
    <>
      <div className="text-center">
        <p>Hello Activity</p>
      </div>
      <BottomFabBtn />
    </>
  );
};

export default Activity;
