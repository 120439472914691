import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SpinnerLoading } from "../Component/SpinnerLoading";
import { useRootContext } from "../context";
import axiosClient from "../Component/axiosClient";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is a required field"),
  password: Yup.string()
    .min(6, "Password must be at least 8 characters")
    .required("Password is a required field"),
});

const Login = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { setToken } = useRootContext();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  const login = async (values) => {
    try {
      setLoading(true);
      let data = JSON.stringify(values);
      const res = await axiosClient.post(`/api/user/login`, data);
      localStorage.setItem("token", res.data.token);
      setToken(res.data.token);
      navigate("/");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error?.response?.status === 402) {
        toast.error(error?.response?.data?.error);
      } else {
        toast.error(error?.response?.data?.message || error?.message);
      }
    }
  };

  return (
    <>
      {loading && (
        <div className="loadingSpinner">
          <SpinnerLoading isLoading={loading} />
        </div>
      )}
      <div className="flex  min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-8">
        <div className="tablet:mx-auto  desktop:w-96 laptop:w-96 tablet:w-96 mobile:w-full  bg-gray-100 p-6 rounded-md">
          <div className="lg:mx-auto sm:w-full sm:max-w-lg ">
            <h2 className="text-center text-2xl font-semi leading-9 tracking-tight text-gray-900">
              Away Planner
            </h2>
          </div>

          <div className="mt-6 lg:mx-auto sm:w-full sm:max-w-lg">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={DisplayingErrorMessagesSchema}
              onSubmit={(values) => {
                login(values);
              }}
            >
              {({ errors, touched, handleSubmit }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  className="space-y-6"
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-1.5">
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-primary-500 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-8"
                      />
                      {touched.email && errors.email && (
                        <div className="text-red-900">{errors.email}</div>
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Password
                      </label>
                    </div>
                    <div className="mt-1.5 relative">
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-primary-500 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-8"
                      />

                      {touched.password && errors.password && (
                        <div className="text-red-900">{errors.password}</div>
                      )}
                    </div>
                    <div className="text-sm mt-1 ">
                      <Link
                        to="/forgot-password"
                        className="font-semibold text-primary-700 hover:text-primary-600"
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex ml-auto mr-auto justify-center rounded-md bg-primary-700 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Login
                    </button>
                  </div>
                </form>
              )}
            </Formik>

            <p className="mt-6 text-center text-md">
              <Link
                to="/signup"
                className="font-semibold leading-6 text-primary-700 hover:text-primary-600"
              >
                Create an account
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
