import React, { useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import lodash from "lodash";
import CalendarStrip from "@appannie/react-infinite-calendar";
import "@appannie/react-infinite-calendar/styles.css";
import moment from "moment";

import { useRootContext } from "../context";
import { SpinnerLoading } from "./SpinnerLoading";
import axiosClient from "./axiosClient";

const PlaceEdit = () => {
  const formatDate1 = useLocation().state?.date;

  const date = formatDate1?.split("T")[0]; // e.g., "2024-09-17"
  const time = useLocation().state?.time; // e.g., "09:10 AM"

  const [formateDate, setFormateDate] = useState(new Date(`${date}T00:00:00`));
  const [selectedTime, setSelectedTime] = useState();
  const address = useLocation().state?.address;
  const [editaddress, setEditAddress] = useState(address);
  const stateValue = useLocation().state?.plansId;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(new Date());
  const [city, setCity] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const { token, isNewPlan, setEditPlan, editPlan, setItems, items } =
    useRootContext();
  const createPlan = items[0];
  const navigate = useNavigate();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [plansname, setPlansname] = useState();
  const [error, setError] = useState(null);
  const [dropdownloading, setDropdownloading] = useState(false);
  const { state } = useLocation();
  const placecity = useLocation().state?.city;
  const latitude = useLocation().state?.latitude;
  const logitude = useLocation().state?.longitude;

  const { id } = useParams();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    setEditPlan([
      {
        ...editPlan[0],
        isEdit: false,
      },
    ]);

    if (editPlan[0]?.isEdit) {
      setValue(new Date(createPlan?.planAddress?.createdAt));
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPlans = async () => {
    const res = await axiosClient.post(
      `/api/plans/createPlans`,
      { name: plansname },
      { headers: { Authorization: `${token}` } }
    );
    return res.data.plansId;
  };

  const handleUpdatePlan = async () => {
    setLoading(true);
    let plansId;
    try {
      if (!formateDate || formateDate === null) {
        setLoading(false);
        return toast.warn("Please select a date and time.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      if (stateValue) {
        plansId = stateValue;
      } else {
        plansId = await createPlans();
      }
      if (!editaddress) {
        setLoading(false);
        return toast.error(
          "No address provided. Please add an address before editing.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }

      let data = {
        place: {
          address: editaddress,
          latitude: createPlan?.latitude ?? latitude,
          longitude: createPlan?.longitude ?? logitude,
          placeId: createPlan?.placeId ?? id,
          type: "places",
          time: selectedTime,
          date: moment(formateDate).format("YYYY-MM-DD"),
          city: city ? city : placecity,
          id: state?.id,
        },
        plansId: stateValue,
        name: plansname,
      };

      await axiosClient.put(`/api/plans/place/${state?.id}`, data, {
        headers: { Authorization: `${token}` },
      });

      setEditPlan([
        {
          ...editPlan[0],
          isEdit: false,
        },
      ]);

      if (isNewPlan) {
        navigate("/", { replace: true });
      } else {
        navigate(`/plans/${plansId}`);
      }

      toast.success("Plan updated successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Something went wrong! Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const fetchPlaceData = useCallback(
    lodash.debounce(async (query) => {
      setDropdownloading(true);
      setError(null);
      const trimmedQuery = query.trim();
      if (!trimmedQuery) {
        setPlaceData([]);
        setIsDropdownVisible(false);
        return;
      }
      try {
        const token = localStorage.getItem("token");
        const res = await axiosClient.get(`/api/places`, {
          params: { fields: trimmedQuery },
          headers: {
            Authorization: `${token}`,
          },
        });
        if (res.status === 200) {
          setPlaceData(Array.isArray(res.data) ? res.data : [res.data]);
        }
      } catch (error) {
        setError("Failed to fetch place data. Please try again.");
        console.error(error);
      } finally {
        setDropdownloading(false);
      }
    }, 500),
    []
  );
  // const handlePlaceSelected = async (place) => {
  //   setLoading(true);

  //   if (place) {
  //     if (place?.name) {
  //       setLoading(false);
  //       return;
  //     }
  //     await fetchPlaceData(place?.place_id);
  //     let city = ""; // Initialize variable to store the city value
  //     const countryComponent = place?.address_components?.find((component) =>
  //       component.types.includes("country")
  //     );
  //     const countryCode = countryComponent ? countryComponent.short_name : null;
  //     place?.address_components?.forEach((component) => {
  //       if (component.types.includes("administrative_area_level_3")) {
  //         city = `${component.long_name},  ${countryCode}`;
  //       } else if (
  //         !city &&
  //         component.types.includes("administrative_area_level_2")
  //       ) {
  //         city = `${component.long_name}, ${countryCode}`;
  //       } else if (
  //         !city &&
  //         component.types.includes("administrative_area_level_3")
  //       ) {
  //         city = `${component.long_name}, ${countryCode}`;
  //       }
  //     });

  //     if (!city) {
  //       city = countryComponent.long_name;
  //     }
  //     setCity(city);
  //     contextData?.setItems([
  //       {
  //         ...createPlan,
  //         address: place?.formatted_address,
  //         latitude: place?.geometry?.location?.lat(),
  //         longitude: place?.geometry?.location?.lng(),
  //         placeId: place?.place_id,
  //       },
  //     ]);
  //   } else {
  //     console.log(place); // Log the actual value of place
  //   }
  // };

  const handleInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setEditAddress(e.target.value);
    fetchPlaceData(value);
    if (value) {
      setIsDropdownVisible(true);
    } else {
      setIsDropdownVisible(false);
    }
  };

  const handleItemClick = (item) => {
    setEditAddress(item.display_name);
    setPlansname(item.display_name);
    setIsDropdownVisible(false);
    let cityname = item.city;

    setCity(cityname);

    setItems([
      {
        ...createPlan,
        city: city,
        address: item.display_name,
        latitude: item.lat,
        longitude: item.lon,
        placeId: item.place_id,
      },
    ]);
  };
  const handleClickOutside = (e) => {
    if (!e.target.closest("#autocomplete-container")) {
      setIsDropdownVisible(false);
    }
  };

  const handleBackPage = () => {
    // navigate(`/places/${id}`);
    navigate(-1);
  };
  //----------------------------------------------------------------------------------------------------------------//
  const [hour, setHour] = useState(1);
  const [minute, setMinute] = useState(0);
  const [period, setPeriod] = useState("AM");

  const updateSelectedTime = (hour, minute, period) => {
    const formattedTime = `${hour < 10 ? `0${hour}` : hour}:${
      minute < 10 ? `0${minute}` : minute
    } ${period}`;
    setSelectedTime(formattedTime);
  };

  useEffect(() => {
    if (time) {
      const [h, m] = time.split(":");
      const minutes = m.split(" ")[0];
      const ampm = m.split(" ")[1];
      const formattedHour = h.length === 1 ? `0${h}` : h;

      setHour(parseInt(formattedHour, 10));
      setMinute(parseInt(minutes, 10));
      setPeriod(ampm);
      updateSelectedTime(formattedHour, minutes, ampm);
    }
  }, [time]);

  const handleTimeChange = () => {
    const hourString = `${hour}`.slice(-2);
    const minuteString = `${minute}`.slice(-2);
    updateSelectedTime(hourString, minuteString, period);
  };
  useEffect(() => {
    handleTimeChange();
  }, [hour, minute, period]);

  const inputRef = useRef(null);

  // Function to handle outside click
  const handleClickOutside1 = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsCalendarVisible(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const handleInputClick = () => {
    setIsCalendarVisible(!isCalendarVisible);
  };

  return (
    <>
      {loading && (
        <div className="loadingSpinner">
          <SpinnerLoading isLoading={loading} />
        </div>
      )}
      <div className="desktop:w-1/2 laptop:w-1/2 tablet:w-8/12 mobile:w-full mx-auto">
        <div className="border-gray-200 border-b-2 py-4 px-5">
          <button
            className=" font-sans text-gray-900 capitalize flex items-center "
            onClick={() => handleBackPage()}
          >
            <Icon
              icon="prime:chevron-left"
              sx={{ fontSize: "20px" }}
              width={"1.5rem"}
              height={"1.5rem"}
            />
            Back
          </button>
        </div>

        <div className="relative rounded-lg mt-5">
          <div id="autocomplete-container" className="relative ">
            <div className="mobile:px-0 px-2">
              <div className="absolute inset-y-0 start-0 mr-5 flex items-center mobile:ps-3 ps-5 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                value={editaddress}
                onChange={handleInputChange}
                className="w-full border border-gray-300 px-4 py-2 pl-10 rounded"
                placeholder="Enter a Location"
              />
            </div>

            {isDropdownVisible && (
              <>
                <ul
                  className={`absolute top-full left-0 w-full bg-white ${
                    Array.isArray(placeData) && placeData?.length > 0
                      ? "border border-gray-300"
                      : "border-0"
                  } rounded mt-1 overflow-auto h-52 z-[1]`}
                >
                  {dropdownloading ? (
                    <div className="px-2 py-2  h-52">
                      <div
                        role="status"
                        className="flex-shrink-0 w-full h-full flex items-center justify-center animate-pulse"
                      >
                        <div className="flex-1 ml-4 ">
                          {Array.from({ length: 6 }).map((_, index) => (
                            <div className="border-b-2 py-2.5">
                              <div
                                key={index}
                                className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-full "
                              ></div>
                            </div>
                          ))}
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {Array.isArray(placeData) && placeData.length > 0 ? (
                        placeData.map((item, index) => (
                          <div
                            key={index}
                            className="flex items-center w-full hover:bg-gray-200 border-b-2 px-3 py-3"
                          >
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.3em"
                                height="1.3em"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#585151"
                                  d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7"
                                />
                              </svg>
                            </div>
                            <button
                              className="flex justify-start cursor-pointer"
                              onClick={() => handleItemClick(item)}
                            >
                              <li className="text-start px-2 py-1">
                                <span>{item.display_name}</span>
                              </li>
                            </button>
                          </div>
                        ))
                      ) : (
                        <div className="flex items-center w-full hover:bg-gray-200 border-b-2 px-4 py-2">
                          <li className="text-center py-2">No results found</li>
                        </div>
                      )}
                    </>
                  )}
                </ul>
              </>
            )}
            {error && <div className="text-red-500 mt-2">{error}</div>}
          </div>
          {/* <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            <Icon icon="carbon:search" className="text-gray-400" />
          </div> */}
        </div>

        <div className="mobile:px-0 px-2">
          <div className="relative  w-full p-3   text-gray-800 rounded-t-lg border border-b-0 border-gray-300 focus:outline-none focus:border-blue-500 mt-3">
            <div className="flex items-center justify-around laptop:flex-row flex-col">
              <div className="date-picker flex items-center relative  border-slate-200 border-2 p-1 rounded-xl laptop:w-1/2 w-full laptop:me-2">
                <div className="flex items-start ">
                  <Icon
                    icon="carbon:calendar"
                    style={{ fontSize: "22px" }}
                    className="text-gray-600"
                  />
                </div>
                <div className="w-full" ref={inputRef}>
                  <input
                    type="text"
                    readOnly
                    value={moment(formateDate).format("YYYY-MM-DD")}
                    onClick={handleInputClick}
                    className="p-[0.35rem] outline-none w-full"
                  />
                  {isCalendarVisible && (
                    <div className="absolute top-[105%] left-0 w-full">
                      <CalendarStrip
                        height={400}
                        selected={formateDate}
                        onSelect={(date) => {
                          setFormateDate(date.toDateString());
                          setIsCalendarVisible(false);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="laptop:py-[20px] laptop:px-[1px] bg-slate-200"></div>
              <div className="time-picker flex items-center border-slate-200 border-2 p-1 rounded-xl laptop:w-1/2  laptop:ms-2   laptop:mt-0 mt-3 w-full">
                <div className="flex items-start ">
                  <Icon
                    icon="iconamoon:clock-light"
                    style={{ fontSize: "22px" }}
                    className="text-gray-600"
                  />
                </div>
                <div className="flex laptop:items-start  px-3 gap-3 laptop:ml-0 ml-2  w-full">
                  <select
                    id="hour"
                    name="h"
                    className="h-auto border border-gray-200 text-gray-600 text-base rounded-lg block mobile:w-12 w-16 py-1.5 focus:outline-none"
                    value={hour}
                    onChange={(e) => {
                      setHour(parseInt(e.target.value, 10));
                      handleTimeChange();
                    }}
                  >
                    {Array.from({ length: 12 }, (_, i) => {
                      const hourValue = (i + 1).toString().padStart(2, "0"); // Pad the hour with '0' if needed
                      return (
                        <option key={i} value={i + 1}>
                          {hourValue}
                        </option>
                      );
                    })}
                  </select>

                  <span className="text-xl font-semibold text-gray-900">:</span>

                  <select
                    id="minute"
                    name="m"
                    className="h-auto border border-gray-300 text-gray-600 text-base rounded-lg block mobile:w-12 w-16 py-1.5 focus:outline-none"
                    value={minute}
                    onChange={(e) => {
                      setMinute(parseInt(e.target.value, 10));
                      handleTimeChange();
                    }}
                  >
                    {Array.from({ length: 12 }, (_, i) => {
                      const minuteValue = (i * 5).toString().padStart(2, "0"); // Pad the minute with '0' if needed
                      return (
                        <option key={i} value={i * 5}>
                          {minuteValue}
                        </option>
                      );
                    })}
                    <option value="59">59</option>{" "}
                    {/* Include 59 as an additional option */}
                  </select>

                  <select
                    id="a"
                    name="a"
                    className="h-auto border border-gray-300 text-gray-600 text-base rounded-lg block mobile:w-12 w-16 py-1.5 focus:outline-none"
                    value={period}
                    onChange={(e) => {
                      setPeriod(e.target.value);
                      handleTimeChange();
                    }}
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="border border-gray-300 rounded-b-lg">
            <div className="mx-3 my-2">
              {editaddress && (
                <div className="flex items-center justify-between cursor-pointer rounded-xl bg-gray-50 mt-2">
                  <div className="flex items-center cursor-pointer p-2">
                    <Icon icon="heroicons:building-office-20-solid" />
                    <p className="ml-2">{editaddress}</p>
                  </div>
                  <button className="create-plan-button pr-2 text-center">
                    <Icon
                      onClick={() => {
                        setEditAddress("");
                      }}
                      icon="heroicons:x-mark-20-solid"
                    />
                  </button>
                </div>
              )}
            </div>
            <div className="text-center border-t border-t-gray-300 ">
              <button
                onClick={handleUpdatePlan}
                className="create-plan-button text-center p-1 text-primary-600 font-medium"
              >
                Update Place
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PlaceEdit;
