import { useEffect, useRef, useState } from "react";
import BottomFabBtn from "../Component/BottomFabBtn";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import CalendarStrip from "@appannie/react-infinite-calendar";
import "@appannie/react-infinite-calendar/styles.css";
import { MdDateRange, MdFlight } from "react-icons/md";

const Flight = () => {
  const [flightNumber, setFlightNumber] = useState("");
  const [flightDate, setFlightDate] = useState(new Date())
  console.log("🚀 ~ Flight ~ flightDate:", flightDate)
  const [flightData, setFlightData] = useState(null);
  console.log("🚀 ~ Flight ~ flightData:", flightData)
  const apiKey = "2386aacc4b77b33e4266bfc40573135d"; // Store the API key in a constant
  const flightDateFormated = moment(flightDate).format('YYYY-MM-DD')
  const fetchFlightData = async () => {
    try {
      if (!flightNumber) {
        toast.error("Please enter a flight number!");
        return;
      }
      const response = await fetch(
        `https://api.aviationstack.com/v1/flights?access_key=${apiKey}&flight_number=${flightNumber}&date=${flightDateFormated}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch flight data");
      }
      const data = await response.json();
      console.log("🚀 ~ fetchFlightData ~ data:", data)
      if (data.data && data.data.length > 0) {
        setFlightData(data.data[0]); // Set the flight data
        toast.success("Flight data retrieved successfully!");
      } else {
        toast.error("No flight data found for the given flight number!");
        setFlightData(null);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };



  const inputRef = useRef(null);

  // Function to handle outside click
  const handleClickOutside1 = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsCalendarVisible(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const handleInputClick = () => {
    setIsCalendarVisible(!isCalendarVisible);
  };


  return (
    <>
      <div className="FlightData w-fit m-auto mobile:px-0 px-2">
        <div className="text-center flex justify-center mobile:flex-row flex-col items-center w-full bg-white border-2 border-slate-200 ">
          <div className="text-center  text-black mobile:w-[40%] w-full flex items-center">
            <div className="flight-icon text-xl w-[10%] mobile:ps-1 ps-2"><MdFlight className="" /></div>
            <input
              type="text"
              placeholder="Add Your Flight Number"
              className="p-2 focus:outline-none focus:ring-0 w-[90%]"
              value={flightNumber}
              onChange={(e) => setFlightNumber(e.target.value.toUpperCase())}
            />
          </div>
          <div className="flightDate text-center  text-black relative mobile:w-[40%] w-full flex items-center mobile:border-l-2 mobile:border-t-0 border-t-2">
            <div className="flight-icon ps-2 text-xl w-[10%]"><MdDateRange /></div>

            <div className="w-[90%]" ref={inputRef}>
              <input
                type="text"
                readOnly
                value={moment(flightDate).format("YYYY-MM-DD")}
                onClick={handleInputClick}
                className="p-[0.35rem] outline-none w-full"
              />
              {isCalendarVisible && (
                <div className="absolute top-[100%] left-0 w-full">
                  <CalendarStrip
                    //  className="ms-2 p-2 bg-slate-300 rounded-lg focus:outline-none focus:ring-0"
                    height={400}
                    selected={flightDate}
                    onSelect={(date) => setFlightDate(date)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center justify-center add-btn mobile:w-[20%] w-full">
            <button
              className="p-4 bg-[#6a7c93] text-white rounded-sm cursor-pointer w-full"
              onClick={fetchFlightData}
            >
              Add
            </button>
          </div>
        </div>
        {/* Flight Data Display */}
        {flightData && (
          <div className="bg-white mt-5 rounded-lg shadow-md max-w-4xl mx-auto">
            <div className="mobile:flex justify-between items-center bg-slate-200 w-100">
              <div className="text-lg ps-4  py-3 font-bold mobile:w-1/4 w-full mobile:text-left text-center">
                <p>{flightData?.flight?.number || "Not Get"}</p>
                <span className="text-sm font-normal">{flightData?.airline?.name || "Not Get"}</span>
              </div>
              <div className="p-2 flex items-center justify-between mobile:w-1/2 w-full mobile:border-t-0 border-t-2 border-slate-300">
                <div className="text-center mr-8 w-[40%]">
                  <div className="font-bold">{flightData?.departure?.iata || "Not Get"}</div>
                  <div className="text-sm text-gray-600">{flightData?.departure?.airport || "Not Get"}</div>
                </div>
                <div className="text-xl">&#9992;</div> {/* Airplane icon */}
                <div className="text-center ml-8 w-[40%]">
                  <div className="font-bold">{flightData?.arrival?.iata || "Not Get"}</div>
                  <div className="text-sm text-gray-600">{flightData?.arrival?.airport || "Not Get"}</div>
                </div>
              </div>
              <div className="p-4 bg-green-500 text-white mobile:w-1/4 w-full mobile:text-left text-center">
                <div className="mobile:pe-16">
                  <p className="">{flightData?.flight_status || "Not Get"}</p>
                  <p className="text-sm">{flightData.live ? 'Airborne' : 'On Ground'}</p>
                </div>
              </div>
            </div>
            {/* Departure and Arrival Info */}
            <div className="mobile:grid grid-cols-2 px-4 bg-white">
              {/* Departure Section */}
              <div className="mobile:border-r-2 py-8">
                <h3 className="text-center text-gray-600 bg-slate-300 w-fit py-1 px-2 m-auto rounded-xl">Departure</h3>
                <div className="text-center text-lg font-bold mt-2">
                  {flightData?.departure?.airport || "Not Get"}
                </div>
                <div className="text-center text-gray-500 text-sm">
                  IATA: {flightData?.departure?.iata || "Not Get"} • ICAO: {flightData?.departure?.icao || "Not Get"}
                </div>
                <div className="grid grid-cols-2 gap-0 mt-4 mobile:pr-4">
                  <div className="text-center bg-slate-200 border-r-2 border-b-2 p-2 border-slate-300">
                    <div className="text-gray-500">Scheduled</div>
                    <div>{moment(flightData?.departure?.scheduled).format('YYYY-MM-DD HH:mm:ss') || "Not Get"}</div>
                  </div>
                  <div className="text-center bg-slate-200 p-2 border-b-2 border-slate-300">
                    <div className="text-gray-500">Estimated</div>
                    <div>{moment(flightData?.departure?.estimated).format('YYYY-MM-DD HH:mm:ss') || "Not Get"}</div>
                  </div>
                  <div className="text-center bg-slate-200  border-r-2 p-2 border-slate-300">
                    <div className="text-gray-500" >Actual</div>
                    <div>{moment(flightData?.departure?.actual).format('YYYY-MM-DD HH:mm:ss') || "Not Get"}</div>
                  </div>
                  <div className="text-center bg-slate-200 p-2">
                    <div className="text-gray-500">Runway</div>
                    <div>{moment(flightData?.departure?.actual_runway).format('YYYY-MM-DD HH:mm:ss') || "Not Get"}</div>
                  </div>
                </div>
                <div className="flex justify-center mt-4 mobile:pr-8">
                  <div className="flex items-center border-[#6a7c93] border-2 bg-gray-200 text-gray-700 pr-2 rounded-md mr-4">
                    <p className="bg-[#6a7c93] text-white rounded-sm p-2"> Terminal</p> <span className="font-bold ps-2">{flightData?.departure?.terminal || "Not Get"}</span>
                  </div>
                  <div className="flex items-center border-[#6a7c93] border-2 bg-gray-200 text-gray-700 pr-2 rounded-md mobile:mr-4">
                    <p className="bg-[#6a7c93] text-white rounded-sm p-2">Gate</p> <span className="font-bold ps-2">{flightData?.departure?.gate || "Not Get"}</span>
                  </div>
                </div>
              </div>
              {/* Arrival Section */}
              <div className="py-8">
                <h3 className="text-center text-gray-600 bg-slate-300 w-fit py-1 px-2 m-auto rounded-xl">Arrival</h3>
                <div className="text-center text-lg font-bold mt-2">
                  {flightData?.arrival?.airport || "Not Get"}
                </div>
                <div className="text-center text-gray-500 text-sm">
                  IATA: {flightData?.arrival?.iata || "Not Get"} • ICAO: {flightData?.arrival?.icao || "Not Get"}
                </div>
                <div className="grid grid-cols-2 gap-0 mt-4 mobile:pl-4">
                  <div className="text-center bg-slate-200 border-r-2 border-b-2 p-2 border-slate-300">
                    <div className="text-gray-500">Scheduled</div>
                    <div>{moment(flightData?.arrival?.scheduled).format('YYYY-MM-DD HH:mm:ss') || "Not Get"}</div>
                  </div>
                  <div className="text-center bg-slate-200 p-2 border-b-2 border-slate-300">
                    <div className="text-gray-500">Estimated</div>
                    <div>{moment(flightData?.arrival?.estimated).format('YYYY-MM-DD HH:mm:ss') || "Not Get"}</div>
                  </div>
                  <div className="text-center bg-slate-200  border-r-2 p-2 border-slate-300">
                    <div className="text-gray-500">Actual</div>
                    <div>{moment(flightData?.arrival?.actual).format('YYYY-MM-DD HH:mm:ss') || "Not Get"}</div>
                  </div>
                  <div className="text-center bg-slate-200 p-2">
                    <div className="text-gray-500">Runway</div>
                    <div>{moment(flightData?.arrival?.actual_runway).format('YYYY-MM-DD HH:mm:ss') || "Not Get"}</div>
                  </div>
                </div>
                <div className="flex justify-center mt-4 mobile:pl-4">
                  <div className="flex items-center bg-gray-200 border-[#6a7c93] border-2 text-white-700 pr-2 rounded-md mr-4">
                    <p className="bg-[#6a7c93] rounded-sm text-white p-2"> Terminal</p> <span className="font-bold ps-2">{flightData?.arrival?.terminal || "Not Get"}</span>
                  </div>
                  <div className="flex items-center border-[#6a7c93] border-2 bg-gray-200 text-gray-700 pr-2 rounded-md mobile:mr-4">
                    <p className="bg-[#6a7c93] text-white rounded-sm p-2">Gate</p> <span className="font-bold ps-2">{flightData?.arrival?.gate || "Not Get"}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Show More Button */}
            <div className="flex justify-center">
              <button className="bg-gray-200 text-gray-700 px-4 py-2 rounded-se-md rounded-ss-md border-b-0 border-slate-300">
                Show More
              </button>
            </div>
            {/* Footer Timezones */}
            <div className="text-center text-sm text-gray-500 mobile:flex px-4 py-2 bg-slate-200">
              <div>• Departure Timezone:{flightData?.departure?.timezone || "Not Get"}</div>
              <div className="ms-2">• Arrival Timezone: {flightData?.arrival?.timezone || "Not Get"}</div>
            </div>
          </div>
        )}
      </div>
      <BottomFabBtn />
    </>
  );
};
export default Flight;