import React, { useEffect, useRef, useState } from "react";

const AppleMapComponent = ({
  markerLocation: { latitude, longitude },
  zoomLevel = 0.01,
  title,
}) => {
  const mapElementRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadMap = async () => {
      try {
        await window.mapkit.init({
          authorizationCallback: (done) => {
            const token = process.env.REACT_APP_APPLE_MAP_API_KEY;
            done(token);
          },
          language: "es",
        });
        const map = new window.mapkit.Map(mapElementRef.current, {
          // mapType: window.mapkit.Map.MapTypes.Hybrid, // Set default to hybrid view
        });
        if (latitude && longitude) {
          const annotation = new window.mapkit.MarkerAnnotation(
            new window.mapkit.Coordinate(latitude, longitude),
            { title }
          );

          map.showItems([annotation]);
          map.region = new window.mapkit.CoordinateRegion(
            annotation.coordinate,
            new window.mapkit.CoordinateSpan(zoomLevel, zoomLevel)
          );
        }
      } catch (error) {
        setError(error);
        console.error("Apple Map: Failed to load map", error);
      }
    };

    loadMap();
  }, [latitude, longitude, title, zoomLevel]);

  if (error) {
    return <div>Error loading map: {error.message}</div>;
  }

  return <div ref={mapElementRef} style={{ width: "auto", height: "400px" }} />;
};

export default AppleMapComponent;
