import React, { useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import lodash from "lodash";
import moment from "moment";
import CalendarStrip from "@appannie/react-infinite-calendar";
import "@appannie/react-infinite-calendar/styles.css";
import { v4 as uuidv4 } from "uuid";

import BottomFabBtn from "../Component/BottomFabBtn";
import { useRootContext } from "../context";
import { SpinnerLoading } from "../Component/SpinnerLoading";
import axiosClient from "../Component/axiosClient";

const PlaceAdd = () => {
  const [isPlaceSelect, setIsPlaceSelect] = useState(false);
  const { state } = useLocation();
  const stateValue = state?.plansId;
  // const [placeInput, setPlaceInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [dropdownloading, setDropdownloading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [formateDate, setFormateDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState();

  const [city, setCity] = useState(null);
  const [placeData, setPlaceData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [plansname, setPlansname] = useState();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [error, setError] = useState(null);

  const { token, isNewPlan, setEditPlan, editPlan, setItems, items } =
    useRootContext();
  const createPlan = items[0];
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    setEditPlan([
      {
        ...editPlan[0],
      },
    ]);

    if (editPlan[0]?.isEdit) {
      setFormateDate(new Date(createPlan?.planAddress?.createdAt));
      setIsEdit(true);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreatePlan = async () => {
    setLoading(true);

    try {
      if (!formateDate) {
        setLoading(false);
        return toast.error("Please select a date and time");
      }
      // const parsedDate = moment(formateDate).format('MMMM D, YYYY');

      const planData = {
        place: {
          address: createPlan?.address,
          latitude: createPlan?.latitude,
          longitude: createPlan?.longitude,
          placeId: createPlan?.placeId,
          type: "places",
          time: selectedTime,
          date: moment(formateDate).format("YYYY-MM-DD"),
          city: city ? city : items[0]?.city,
          id: uuidv4(),
        },
        plansId: stateValue,
        name: plansname,
      };

      if (isEdit) {
        const id = items[0].planAddress.id;
        await axiosClient.put(`/api/plans/updatePlans/${id}`, planData, {
          headers: { Authorization: `${token}` },
        });
        setEditPlan([{ ...editPlan[0], isEdit: false }]);
        setIsEdit(false);
      } else {
        await axiosClient.post(`/api/plans/createPlans`, planData, {
          headers: { Authorization: `${token}` },
        });
      }

      if (isNewPlan) {
        navigate("/", { replace: true });
      } else if (stateValue) {
        navigate(`/plans/${stateValue}`);
      } else {
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const fetchPlaceData = useCallback(
    lodash.debounce(async (query) => {
      setDropdownloading(true);
      setError(null);
      const trimmedQuery = query.trim();
      if (!trimmedQuery) {
        setPlaceData([]);
        setIsDropdownVisible(false);
        return;
      }
      try {
        const token = localStorage.getItem("token");
        const res = await axiosClient.get(`/api/places`, {
          params: { fields: trimmedQuery },
          headers: {
            Authorization: `${token}`,
          },
        });
        if (res.status === 200) {
          setPlaceData(Array.isArray(res.data) ? res.data : [res.data]);
        }
      } catch (error) {
        setError("Failed to fetch place data. Please try again.");
        console.error(error);
      } finally {
        setDropdownloading(false);
      }
    }, 500),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setInputValue(e.target.value);
    fetchPlaceData(value);
    if (value) {
      setIsDropdownVisible(true);
    } else {
      setIsDropdownVisible(false);
    }
  };

  const handleItemClick = (item) => {
    setInputValue(item.display_name);
    setPlansname(item.display_name);
    setIsDropdownVisible(false);
    let cityname = item.city;

    setCity(cityname);

    setItems([
      {
        ...createPlan,
        city: city,
        address: item.display_name,
        latitude: item.lat,
        longitude: item.lon,
        placeId: item.place_id,
      },
    ]);

    setIsPlaceSelect(true);
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest("#autocomplete-container")) {
      setIsDropdownVisible(false);
    }
  };

  const handleBackPage = () => {
    if (isNewPlan) {
      navigate(`/`);
    } else {
      navigate(`/plans/${stateValue}`);
    }
  };

  const [hour, setHour] = useState(1);
  const [minute, setMinute] = useState(0);
  const [period, setPeriod] = useState("AM");

  const updateSelectedTime = (hour, minute, period) => {
    const formattedTime = `${hour < 10 ? `0${hour}` : hour}:${
      minute < 10 ? `0${minute}` : minute
    } ${period}`;
    setSelectedTime(formattedTime);
  };

  const handleTimeChange = () => {
    const hourString = `${hour}`.slice(-2);
    const minuteString = `${minute}`.slice(-2);
    updateSelectedTime(hourString, minuteString, period);
  };
  useEffect(() => {
    handleTimeChange();
  }, [hour, minute, period]);

  const inputRef = useRef(null);

  // Function to handle outside click
  const handleClickOutside1 = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsCalendarVisible(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const handleInputClick = () => {
    setIsCalendarVisible(!isCalendarVisible);
  };

  return (
    <>
      {loading && (
        <div className="loadingSpinner">
          <SpinnerLoading isLoading={loading} />
        </div>
      )}
      <div className="desktop:w-1/2 laptop:w-1/2 tablet:w-8/12 mobile:w-full mx-auto">
        <div className="border-gray-200 border-b-2 pb-5 px-4">
          <button
            className=" font-sans text-gray-900 capitalize flex items-center "
            onClick={() => handleBackPage()}
          >
            <Icon icon="prime:chevron-left" sx={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
        {!isPlaceSelect ? (
          <div className="relative rounded-lg  mt-5">
            <div id="autocomplete-container" className="relative ">
              <div className="search mobile:px-0 px-2">
                <div className="absolute inset-y-0 start-0 mr-5 flex items-center mobile:ps-3 ps-5 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 px-4 py-2 pl-10 rounded"
                  placeholder="Enter a Location"
                />
              </div>

              {isDropdownVisible && (
                <>
                  <ul
                    className={`absolute top-full left-0 mobile:w-full smallMobile:w-[96%] w-[94%] mobile:mx-0 mx-[10px] bg-white ${
                      Array.isArray(placeData) && placeData.length > 0
                        ? "border border-gray-300"
                        : "border-0"
                    } rounded mt-1 overflow-auto h-52`}
                  >
                    {dropdownloading ? (
                      <div className="px-2 py-2  h-52">
                        <div
                          role="status"
                          className="flex-shrink-0 w-full h-full flex items-center justify-center animate-pulse"
                        >
                          <div className="flex-1 ml-4 ">
                            {Array.from({ length: 6 }).map((_, index) => (
                              <div className="border-b-2 py-2.5">
                                <div
                                  key={index}
                                  className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-full "
                                ></div>
                              </div>
                            ))}
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {Array.isArray(placeData) && placeData.length > 0 ? (
                          placeData.map((item, index) => (
                            <div
                              key={index}
                              className="flex items-center w-full hover:bg-gray-200 border-b-2 px-3 py-3"
                            >
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1.3em"
                                  height="1.3em"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#585151"
                                    d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7"
                                  />
                                </svg>
                              </div>
                              <button
                                className="flex justify-start cursor-pointer"
                                onClick={() => handleItemClick(item)}
                              >
                                <li className="text-start px-2 py-1">
                                  <span>{item.display_name}</span>
                                </li>
                              </button>
                            </div>
                          ))
                        ) : (
                          <div className="flex items-center w-full hover:bg-gray-200 border-b-2 px-4 py-2">
                            <li className="text-center py-2">
                              No results found
                            </li>
                          </div>
                        )}
                      </>
                    )}
                  </ul>
                </>
              )}
              {error && <div className="text-red-500 mt-2">{error}</div>}
            </div>
          </div>
        ) : (
          <>
            <div className="mobile:px-0 px-3">
              <div className="relative  w-full p-3   text-gray-800 rounded-t-lg border border-b-0 border-gray-300 focus:outline-none focus:border-blue-500 mt-3">
                <div className="flex items-center justify-around laptop:flex-row flex-col">
                  <div className="date-picker flex items-center relative  border-slate-200 border-2 p-1 rounded-xl laptop:me-2 laptop:w-1/2 w-full">
                    <div className="flex items-start ">
                      <Icon
                        icon="carbon:calendar"
                        style={{ fontSize: "22px" }}
                        className="text-gray-600"
                      />
                    </div>
                    <div className="w-full" ref={inputRef}>
                      <input
                        type="text"
                        readOnly
                        value={moment(formateDate).format("yyyy-MM-DD")}
                        onClick={handleInputClick}
                        className="p-[0.35rem] outline-none w-full"
                      />
                      {isCalendarVisible && (
                        <div className="absolute top-[105%] left-0 w-full">
                          <CalendarStrip
                            height={400}
                            selected={formateDate}
                            onSelect={(date) => {
                              setFormateDate(date.toDateString());
                              setIsCalendarVisible(false);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="laptop:py-[20px] laptop:px-[1px] bg-slate-200"></div>
                  <div className="time-picker flex items-center border-slate-200 border-2 p-1 rounded-xl laptop:w-1/2  laptop:ms-2  laptop:mt-0 mt-3 w-full">
                    <div className="flex items-start ">
                      <Icon
                        icon="iconamoon:clock-light"
                        style={{ fontSize: "22px" }}
                        className="text-gray-600"
                      />
                    </div>
                    <div className="flex laptop:items-start  px-3 gap-3 laptop:ml-0 ml-2  w-full">
                      <select
                        id="hour"
                        name="h"
                        className="h-auto border border-gray-300 text-gray-600 text-base rounded-lg block w-12 py-1.5 focus:outline-none"
                        value={hour}
                        onChange={(e) => {
                          setHour(parseInt(e.target.value, 10));
                          handleTimeChange();
                        }}
                      >
                        {Array.from({ length: 12 }, (_, i) => {
                          const hourValue = (i + 1).toString().padStart(2, "0"); // Pad the hour with '0' if needed
                          return (
                            <option key={i} value={i + 1}>
                              {hourValue}
                            </option>
                          );
                        })}
                      </select>

                      <span className="text-xl font-semibold text-gray-900">
                        :
                      </span>

                      <select
                        id="minute"
                        name="m"
                        className="h-auto border border-gray-300 text-gray-600 text-base rounded-lg block w-12 py-1.5 focus:outline-none"
                        value={minute}
                        onChange={(e) => {
                          setMinute(parseInt(e.target.value, 10));
                          handleTimeChange();
                        }}
                      >
                        {Array.from({ length: 12 }, (_, i) => {
                          const minuteValue = (i * 5)
                            .toString()
                            .padStart(2, "0"); // Pad the minute with '0' if needed
                          return (
                            <option key={i} value={i * 5}>
                              {minuteValue}
                            </option>
                          );
                        })}
                        <option value="59">59</option>{" "}
                        {/* Include 59 as an additional option */}
                      </select>

                      <select
                        id="a"
                        name="a"
                        className="h-auto border border-gray-300 text-gray-600 text-base rounded-lg block w-12 py-1.5 focus:outline-none"
                        value={period}
                        onChange={(e) => {
                          setPeriod(e.target.value);
                          handleTimeChange();
                        }}
                      >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border border-gray-300 rounded-b-lg">
                <div className="mx-3 my-2">
                  <div className="flex items-center justify-between cursor-pointer rounded-xl bg-gray-50 mt-2">
                    <div className="flex items-center cursor-pointer p-2">
                      <Icon icon="heroicons:building-office-20-solid" />
                      <p className="ml-2">{placeData[0]?.display_name}</p>
                    </div>
                    <button className="create-plan-button pr-2 text-center">
                      <Icon
                        onClick={() => {
                          // setPlaceInput("");
                          setIsPlaceSelect(false);
                        }}
                        icon="heroicons:x-mark-20-solid"
                      />
                    </button>
                  </div>
                </div>
                <div className="text-center border-t border-t-gray-300 ">
                  <button
                    onClick={handleCreatePlan}
                    className="create-plan-button text-center p-1 text-primary-600 font-medium"
                  >
                    {isEdit
                      ? isNewPlan === true
                        ? "Update plan"
                        : "Update place"
                      : isNewPlan
                      ? "Create plan"
                      : "Create place"}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <BottomFabBtn />
    </>
  );
};

export default PlaceAdd;
