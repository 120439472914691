import BottomFabBtn from "../Component/BottomFabBtn";

const Car = () => {
  return (
    <>
      <div className="text-center">
        <p>Hello car</p>
      </div>
      <BottomFabBtn />
    </>
  );
};

export default Car;
