import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { SpinnerLoading } from "../Component/SpinnerLoading";
import axiosClient from "../Component/axiosClient";

const Profile = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
  });
  const [loading, setLoading] = useState(true);
  const [passwordData, setPasswordData] = useState({});
  const token = localStorage.getItem("token");

  const fetchUserData = async () => {
    try {
      const res = await axiosClient.get(`/api/user/getUser`, {
        headers: { Authorization: `${token}` },
      });
      setUserData(res.data);
      setLoading(false);
    } catch (error) {
      // toast.error("an error occured Please login again!");
      navigate("/login");
      console.log(error);
    }
  };

  const updateUserData = async () => {
    try {
      await axiosClient.put(`/api/user/updateUser`, userData, {
        headers: { Authorization: `${token}` },
      });
      // toast.success(res.data.message);
      navigate("/");
    } catch (error) {
      // toast.error("an error occured Please login again!");
      navigate("/login");
      console.log(error);
    }
  };

  const handlePasswordChange = async () => {
    try {
      if (
        !passwordData.newPassword ||
        !passwordData.password ||
        passwordData.newPassword?.length < 8
      ) {
        setLoading(false);
        // return toast.info("minimum length of password should be 8");
      }
      await axiosClient.put(`/api/user/updatePassword`, passwordData, {
        headers: { Authorization: `${token}` },
      });
      setLoading(false);
      // toast.success(res.data.message);
      navigate("/");
    } catch (error) {
      setPasswordData({
        password: "",
        newPassword: "",
      });
      // toast.error(error.response.data.error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && (
        <div className="loadingSpinner">
          <SpinnerLoading isLoading={loading} />
        </div>
      )}
      <div className="desktop:flex desktop:w-8/12 mx-auto laptop:flex laptop:w-10/12">
        <div className="flex min-h-full flex-1 flex-col justify-center py-6 px-4  lg:px-8 mt-8">
          <div className="mx-auto mobile:w-96 w-full sm:max-w-lg bg-gray-100 p-6 rounded-md">
            <div className="lg:mx-auto sm:w-full sm:max-w-lg ">
              <h2 className="text-center text-2xl font-semi leading-9 tracking-tight text-gray-900">
                Away Planner - Profile
              </h2>
            </div>

            <div className="mt-6 lg:mx-auto sm:w-full sm:max-w-lg">
              <form className="space-y-6">
                <div>
                  <label
                    htmlFor="fname"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    first name
                  </label>
                  <div className="mt-1.5">
                    <input
                      id="fname"
                      name="fname"
                      type="text"
                      value={userData.firstName}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          name: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-primary-500 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-8"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="lname"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    last name
                  </label>
                  <div className="mt-1.5">
                    <input
                      value={userData.lastName}
                      id="lname"
                      name="lname"
                      type="text"
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          lastName: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-primary-500 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-8"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Phone Number
                  </label>
                  <div className="mt-1.5">
                    <input
                      value={userData.phone}
                      id="phone"
                      name="phone"
                      type="number"
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          phone: e.target.value,
                        })
                      }
                      className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-primary-500 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-8"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-1.5">
                    <input
                      disabled={true}
                      value={userData.email}
                      id="email"
                      className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-primary-500 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-8"
                    />
                  </div>
                </div>
                <div className="flex">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                    }}
                    className="flex ml-auto mr-auto justify-center rounded-md bg-primary-700 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={false}
                    onClick={(e) => {
                      setLoading(true);
                      e.preventDefault();
                      updateUserData();
                    }}
                    className="flex ml-auto mr-auto justify-center rounded-md 
                   bg-primary-700 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="flex min-h-full flex-1 flex-col justify-center pb-12 px-4  mt-8">
          <div className="mx-auto mobile:w-96 w-full sm:max-w-lg bg-gray-100 p-6 rounded-md">
            <div className="lg:mx-auto sm:w-full sm:max-w-lg ">
              <h2 className="text-center text-2xl font-semi leading-9 tracking-tight text-gray-900">
                Away Planner - Change Password
              </h2>
            </div>

            <div className="mt-6 lg:mx-auto sm:w-full sm:max-w-lg">
              <form className="space-y-6">
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="cpassword"
                      className="block text-center text-sm font-medium leading-6 text-gray-900"
                    >
                      Current Password
                    </label>
                  </div>
                  <div className="mt-1.5 relative">
                    <input
                      onChange={(e) =>
                        setPasswordData({
                          ...passwordData,
                          password: e.target.value,
                        })
                      }
                      value={passwordData.password}
                      id="cpassword"
                      name="cpassword"
                      type="password"
                      className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-primary-500 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-8"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="npassword"
                      className="block text-center text-sm font-medium leading-6 text-gray-900"
                    >
                      New Password
                    </label>
                  </div>
                  <div className="mt-1.5 relative">
                    <input
                      onChange={(e) =>
                        setPasswordData({
                          ...passwordData,
                          newPassword: e.target.value,
                        })
                      }
                      value={passwordData.newPassword}
                      id="npassword"
                      name="npassword"
                      type="password"
                      className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-primary-500 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-8"
                    />
                  </div>
                </div>
                <div>
                  <button
                    onClick={(e) => {
                      setLoading(true);
                      e.preventDefault();
                      handlePasswordChange();
                    }}
                    className="flex ml-auto mr-auto justify-center rounded-md bg-primary-700 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Change Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
